<template>
  <div class="fullPage">
    <van-nav-bar title="选择支付方式" left-arrow @click-left="$router.go(-1)" />
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="selectPayMethod(item.id)"
      >
        <div class="item-left">
          <div class="title">{{ item.title }}</div>
          <div class="sub">{{ item.sub }}</div>
        </div>
        <div class="item-right">
          <van-image width="40" height="40" :src="item.img" />
        </div>
      </div>
    </div>
    <!-- 
    <van-popup v-model="showPicker" position="center">
      <vue-qr
        :text="payInfo.rqCode"
        :size="250"
        :logoSrc="logo"
        :logoScale="0.2"
      >
      </vue-qr>
      <div
        class="d-flex row-center padding-b-20 font-20"
        style="color: #444"
        v-if="payInfo.money"
      >
        ￥{{ $price(payInfo.money) }}
      </div>
    </van-popup> -->

    <!-- <div class="d-flex row-around" style="margin: 16px">
      <van-button type="info" @click="toPage(1)"> 确认支付 </van-button>
      <van-button plain @click="toPage(0)"> 取消支付 </van-button>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/logo.png"),
      list: [
        {
          title: "微信付款",
          sub: "WeChat",
          id: 2,
          img: require("../../assets/1.png"),
        },
        {
          title: "支付宝付款",
          sub: "Alipay",
          id: 3,
          img: require("../../assets/2.png"),
        },
        {
          title: "现金缴费",
          sub: "Money",
          id: 1,
          img: require("../../assets/logo.png"),
        },
      ],
      id: "",
      price: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.price = this.$route.query.price;
  },
  methods: {
    toPage(num) {
      if (num === 1) {
        //支付成功的一些方法
      }
      //返回到最开始的一级
      let backlen = window.history.length - 1;
      window.history.go(-backlen);
      //替换这一个到想要的位置
      setTimeout(() => {
        this.$router.replace({ path: "/list" }).catch(() => {});
      }, 100);
    },
    selectPayMethod(id) {
      if (id === 1) {
        this.$router.push({
          path: "/on-site-payment?id=" + this.id + "&price=" + this.price,
        });
      } else {
        this.$router.push({
          path: `/pay?id=${this.id}&type=${id}`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fullPage {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f6fafd !important;
}
.list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 4% 0;
}
.item {
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 45%;

  min-width: 200px;
  height: 80px;
  justify-content: space-between;
  padding: 10px;
  border-radius: 6px;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;

  .title {
    color: #9f5315;
    font-size: 18px;
  }
  .sub {
    color: #dfd47d;
  }
}

.item-right {
  display: flex;
}
</style>
